import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../../components/Core";
import { device } from "../../../utils";

import flopPmLogoLightPng from "../../../assets/image/flop/flop-pm-logo-light.png";
import flopPmPreviewPng from "../../../assets/image/flop/products/flop-pm/flop-pm-presentation.png";

const ImgRight = styled.img`
  max-width: 50%;
  @media ${device.sm} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="black" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 5, 3, 5]}>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="8" sm="9" className="order-lg-2">
                <div className="text-center text-lg-right position-relative">
                  <div
                    className="img-main"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    <ImgRight
                      src={flopPmPreviewPng}
                      alt="Flop PM Presentation - The poker room tool you need"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box pr={5} pt={[null]}>
                    <img
                      className="mb-3"
                      src={flopPmLogoLightPng}
                      alt="flop PM logo light for presentation - The poker room tool you need."
                    />
                    <Title color="light" variant="hero">
                      Revolutionize Your Poker Room Operations
                    </Title>
                    <Text color="light" mb={4}>
                      Looking to revamp your land-based poker room operations? Look no further. First Land of Poker presents Flop PM, a groundbreaking digital solution designed specifically for enhancing poker room management and boosting profitability.
                    </Text>
                    <div className="d-flex flex-column align-items-start mb-3">
                      <a href={"https://flop-pm.firstlandofpoker.com/user-auth/sign-up"} target="_blank">
                        <Button bg="#4D8DCC" mb={3}>
                          Get Started
                        </Button>
                      </a>
                    </div>
                  </Box>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default Hero;
