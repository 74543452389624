import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/flop-products/flop-pm/Hero";
import Form from "../sections/flop-products/flop-pm/Form";
import KeyFeatures from "../sections/flop-products/flop-pm/KeyFeatures";
import PMToApp from "../sections/flop-products/flop-pm/PMToApp";
import PMOptingIntro from "../sections/flop-products/flop-pm/PMOptingIntro";
import KI from "../sections/flop-products/flop-pm/KI";
import PMGetQuote from "../sections/flop-products/flop-pm/PMGetQuote";
import Seo from "../components/Seo/seo";

const FlopPmPage = () => {
  return (
    <PageWrapper headerDark>
      <Seo
        title="Flop PM"
        desc="Manage your poker room with the best tool ever."
      />
      <Hero />
      <KeyFeatures />
      <PMToApp />
      <PMOptingIntro />
      <KI />
      <PMGetQuote />
      <Form />
    </PageWrapper>
  );
};

export default FlopPmPage;
