import React from "react";
import { Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../../components/Core";
import flopPmToFlopApp from "../../../assets/image/flop/products/flop-pm/flopPmToFlopApp.png";

const PMToApp = () => {
  return (
    <>
      <Section bg="black" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={null}>
          <Row className="mb-3 text-center">
            <Col lg="12">
              <Title color="light" >
                Flop PM and Flop App: Seamless Integration for a Unified Experience
              </Title>
            </Col>
          </Row>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" style={{paddingLeft: 20, paddingRight: 20}}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box>
                    <h3 className={'greenLinearGradientText'}  mb={2}>
                      Full Integration for Smooth Operations
                    </h3>
                    <Text color="light"  mb={4}>
                      Flop PM, our groundbreaking poker room management software, integrates flawlessly with Flop App, our dedicated app for poker players. This allows for an efficient and seamless flow of information between the two platforms, streamlining operations and enhancing the player experience.
                    </Text>
                    <h3 className={'purpleLinearGradientText'}  mb={2}>
                      Uninterrupted Communication for Enhanced Experience
                    </h3>
                    <Text color="light"  mb={4}>
                      This integration ensures uninterrupted communication between poker rooms and players. It gives players real-time updates about running games, their seats, and even their game history, enhancing their poker experience. On the other side, poker room operators can track player activity, preferences, and feedback, enabling them to provide a tailored experience and optimize their operations.
                    </Text>
                    <h3 className={'orangeLinearGradientText'}  mb={2}>
                      Personalized Poker Journey
                    </h3>
                    <Text color="light"  mb={4}>
                      Through Flop PM and Flop App's full integration and communication, we are empowering a personalized poker journey. Players get to enjoy a seamless, tailored poker experience, while operators gain the ability to customize and optimize their operations based on real-time insights.
                    </Text>
                  </Box>
                </div>
              </Col>
              <Col lg="6" md="8" sm="9" className="mb-5 mb-lg-0">
                <div className="text-center position-relative">
                  <div
                      className="img-main"
                      data-aos="fade-left"
                      data-aos-duration="750"
                      data-aos-once="true"
                      data-aos-delay="500"
                  >
                    <img
                        alt="Flop PM Brings Power"
                        className="img-fluid"
                        src={flopPmToFlopApp}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default PMToApp;
