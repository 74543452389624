import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Text, Title, Button } from "../../../components/Core";
import FlopFormattedMessageWrapper from "../../../components/Flop/FlopFormattedMessageWrapper";

const PMGetQuote = () => (
  <>
    <Section bg="greyBg">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="9" className="text-center">
            <div>
              <Title>
                Discover Our Competitive Pricing
              </Title>
              <Text>
                At First Land of Poker, we believe in delivering outstanding value. That's why we've structured our pricing to reflect the quality and impact of our innovative solutions, Flop App and Flop PM.
                For poker room operators looking to streamline operations, we offer a range of packages and pricing options to cater to all.<br/>
                To help us understand your needs better and provide a personalized quote, we encourage you to get in touch with our friendly team. <br />
                Click on the "Get a Quote" button below, and we'll guide you through our simple and efficient process.
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="align-items-center">
            <div className="d-flex flex-column align-items-center my-3">
              <a href="#form">
                <Button mb={3} bg="blue" variant={'solid'}>
                  Get a Quote
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default PMGetQuote;
