import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../../components/Core";

export const FeatureCard = ({ color = "primary", iconName, children, ...rest }) => (
  <Box pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <i className={iconName}></i>
    </Box>
    <div className="text-center">
      <Text fontSize={2} lineHeight={1.75} my={3}>
        {children}
      </Text>
    </div>
  </Box>
);

const KeyFeatures = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="mb-3 text-center">
          <Col lg="12">
            <Title>
              Key Features
            </Title>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="lightGreen" iconName="fas fa-list-ul">
              Lists running Cash Games in real time to the FLOP App community
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="red" iconName="far fa-lightbulb">
              Smart Waiting List Management
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="blue" iconName="fas fa-chart-bar">
              Track your Poker Business KPIs through our Dashboard
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="purpleSecond" iconName="fas fa-cogs">
              Manage Interest Lists of new games requested by players or created by your staff
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="orangeSecond" iconName="far fa-bell">
              Alert players in the Waiting List through Notifications Push when a Seat is open
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard color="secondary" iconName="fas fa-chart-line">
              Maximize poker revenue using yield management recommendations
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default KeyFeatures;
