import React from "react";
import { Row, Col } from "react-bootstrap";

import { Section, Box, Text, Title } from "../../../components/Core";
import artificialIntelligence from "../../../assets/image/flop/products/flop-pm/artificial-intelligence.png";

const KI = () => (
  <>
    <Section bg="black" className="position-relative">
      <Box px={[1, 1, 1, 5, 6]} pt={null}>
        <Col>
          <Row className="justify-content-center align-items-center">
            <Col lg="6" style={{paddingLeft: 20, paddingRight: 20}}>
              <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
              >
                <Box>
                  <Title color="light" mb={4}>
                    Harness the Power of AI for Profitable Poker Business
                  </Title>
                  <Text color="light">
                    Flop PM is powered by an advanced Yield Management Engine that leverages machine learning algorithms. This state-of-the-art poker room software utilizes AI technology to optimize your room utilization and game selection, contributing to your poker business's profitability and growth.
                  </Text>
                </Box>
              </div>
            </Col>
            <Col lg="6" md="8" sm="9" className="mb-5 mb-lg-0">
              <div className="text-center position-relative">
                <div
                    className="img-main"
                    data-aos="fade-left"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                >
                  <img
                      alt="Flop PM Brings Power"
                      className="img-fluid"
                      src={artificialIntelligence}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Box>
    </Section>
  </>
);

export default KI;
