import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import {Title, Section, Box, Text, Button} from "../../../components/Core";
import {FeatureCard} from "./KeyFeatures";

const PMOptingIntro = () => {
  return (
    <>
      <Section className="position-relative">
        <Container>
          <Row className="mb-3 text-center">
            <Col lg="12">
              <Title>
                Opting Into Flop PM: A Seamless Transition
              </Title>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5 text-center">
              <FeatureCard color="lightGreen" iconName="far fa-envelope">
                Embarking on your Flop PM journey couldn't be easier. Just reach out to our dedicated team who will provide a tailored demonstration of Flop PM's capabilities. We'll guide you through the integration process, ensuring a seamless transition with minimal disruption to your operations.
                Contact us to get started!
              </FeatureCard>
              <Button href={'#form'} variant={'greenLinear'} bgImage={["#3CE4C1", "#239AF7"]} mb={3}>
                Contact Us
              </Button>
            </Col>
            <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5 text-center">
              <FeatureCard color="purpleSecond" iconName="far fa-star">
                Take your poker room operations to the next level with Flop PM, our revolutionary poker room management software. We're currently offering a FREE trial for all new users! Experience firsthand how Flop PM can transform your business, streamline operations, and enhance profitability.
              </FeatureCard>
              <Button
                  href={"https://flop-pm.firstlandofpoker.com/user-auth/sign-up"}
                  target="_blank"
                  variant={'greenLinear'}
                  bgImage={["#B957D0", "#693EFC"]}
                  mb={3}
              >
                Start for FREE
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default PMOptingIntro;
